import * as regexp from 'lib/regexp';

export const getEnvValue = <T extends string>(env: T | undefined): T | undefined => env?.replaceAll('\'', '"') as T;

export const parseEnvJson = <DataType>(env: string | undefined): DataType | null => {
  try {
    return JSON.parse(env || 'null') as DataType | null;
  } catch (error) {
    return null;
  }
};

export const getExternalAssetFilePath = (envName: string, envValue: string | undefined) => {
  const parsedValue = getEnvValue(envValue);

  if (!parsedValue) {
    return;
  }
  // console.log("getExternalAssetFilePath: ****", parsedValue);
  

  const fileName = envName.replace(/^NEXT_PUBLIC_/, '').replace(/_URL$/, '').toLowerCase();
  const fileExtension = parsedValue.match(regexp.FILE_EXTENSION)?.[1];

  // return `/assets/${ fileName }.${ fileExtension }`;
  return parsedValue;
};

export const getSymbol = (addressToken: string) => {
  
  switch (addressToken) {
    case "0x196d3f09A293e0ee4aD4e291cC142908aA02303d":
      return '0xb8c77482e45f1f44de1745f52c74426c631bdd52'
    case "0x254321De2ba6fD4722d9e275e4b99e4191B93173":
      return '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
    case "0x219241e009C47c82EA764F873691E8d830218580":
      return '0xdac17f958d2ee523a2206206994597c13d831ec7'
    case "0x45C3dEd9650b98d53ec27DA474D292cdF7B5F738":
      return '0xdac17f958d2ee523a2206206994597c13d831ec7'

    default:
      return ""
  }
} 
